<template>
    <el-row>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <Breadcrumb :title="$t('industry.' + category)"></Breadcrumb>
            <el-container>
                <el-mai style="padding:0; !important">
                    <el-container v-for="(item, index) in industry.datas" :key="index">
                        <el-main>
                            <el-container style="margin-bottom: 20px;">
                                <el-text class="title">{{ $t(category + '.' + item.key) }}</el-text>
                            </el-container>
                            <el-container v-for="(desc, i) in item.description" :key="i" style="margin-bottom: 20px;">
                                <el-text class="description">{{ $t(category + '.' + desc) }}</el-text>
                            </el-container>
                            <el-container>
                                <el-row>
                                    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-for="(path, j) in item.images"
                                        :key="j">
                                        <el-image :src="path" fit="fill" :preview-src-list="item.images" :initial-index="j"
                                            :hide-on-click-modal="true"></el-image>
                                    </el-col>
                                </el-row>
                            </el-container>
                        </el-main>
                    </el-container>
                </el-mai>
            </el-container>
        </el-col>
    </el-row>
</template>
    
<script>
import industries from "/public/Json/industry.json";
import Breadcrumb from "../../../components/Breadcrumb.vue"

export default {
    name: "Common",
    components: {
        Breadcrumb
    },
    data() {
        return {
            category: this.$route.params.category,
            industries,
            industry: {}
        };
    },
    mounted: function () {
        this.industry = industries.datas.find(i => i.category === this.category)
    }
};
</script>
    
<style scoped>
:deep(.el-table__header-wrapper) {
    font-size: large;
}

:deep(.el-table--enable-row-hover .el-table__body tr:hover > td) {
    background: rgb(142, 195, 31) !important;
}

.title {
    font-size: large;
    font-weight: bold;
}

.description {
    text-indent: 2em;
}
</style>